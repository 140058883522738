<template>
  <div class="relation-chart">
    <!-- 左侧项目内容 -->
    <div class="relation-chart-left" ref="relationChartLeft">
      <proj-list @relSelectd="relSelectd" @projSelectd="projSelectd" />
      <img
        class="img-left"
        src="@/assets/chart/icon-proj-left.png"
        @click="showProjListChanged(false)"
        v-if="showProjList == true"
      />
    </div>
    <!-- 右侧图谱内容 -->
    <div class="relation-chart-right">
      <img
        class="img-right"
        src="@/assets/chart/icon-proj-right.png"
        @click="showProjListChanged(true)"
        v-if="showProjList == false"
      />

      <div class="tabs">
        <div class="tab-item" :class="{ 'tab-item-active': curTab == 0 }" @click="curTabChanged(0)">
          图谱
        </div>
        <div class="tab-item" :class="{ 'tab-item-active': curTab == 1 }" @click="curTabChanged(1)">
          监控
        </div>
      </div>

      <monitor v-if="curTab == 1" :proj="curProj" :tokenCode="tokenType" ref="monitor" />

      <!-- 上半部分 -->
      <div class="relation-chart-top" v-show="curTab == 0">
        <relation-search ref="relSearch" @submitClick="searchClick" :addrInfo="projDetail" />
      </div>

      <!-- 下半部分 -->
      <div class="relation-chart-bottom" v-show="curTab == 0">
        <div class="fullscreen-container" ref="chart">
          <!-- 左侧内容 -->
          <div class="left" ref="chartLeft">
            <relation-info
              v-show="showEdit"
              ref="relInfo"
              :addrInfo="addrInfo"
              :type="popType"
              :tokenType="tokenType"
              :curNode="curNode"
              @closeEditBtn="closeEditBtn"
            />
          </div>
          <!-- 右侧内容 -->
          <div class="right">
            <!-- 关系图谱 -->
            <div class="chart chart-content">
              <!-- 左侧展开按钮 -->
              <img
                class="left-show-btn"
                src="@/assets/chart/icon-info-show.png"
                v-if="!showEdit"
                @click="showEditBtn"
              />
              <div id="mountNode" ref="mountNode" class="mountNode"></div>
              <!-- 底部流入流出查询控制 -->
              <div class="display">
                <div class="display-container">
                  <div class="display-container-item" @click="getAddrRelation(0)">
                    <img src="@/assets/chart/icon-chart-target.png" />
                    <div>查看资金来源</div>
                  </div>
                  <div class="display-container-item" @click="getAddrRelation(1)">
                    <img src="@/assets/chart/icon-chart-source.png" />
                    <div>查看资金去向</div>
                  </div>
                  <div class="display-container-item" @click="getRelationCount()">
                    <img src="@/assets/chart/icon-chart-all.png" />
                    <div>查看全部交易对手</div>
                  </div>
                  <div class="display-container-item" @click="delRelation()">
                    <img src="@/assets/chart/icon-chart-delete.png" />
                    <div>删除地址</div>
                  </div>
                  <!-- <div class="display-container-item" @click="getLabelRelation()">
                    <img src="@/assets/chart/icon-chart-jys.png" />
                    <div>查看交易所</div>
                  </div> -->
                </div>
              </div>
              <!-- 右侧视图控制器 -->
              <el-popover
                class="filter"
                placement="left"
                width="260"
                trigger="click"
                v-model="showPopover"
              >
                <el-form ref="filterForm" :model="filterForm" label-width="110px">
                  <el-form-item label="开始时间">
                    <el-date-picker
                      type="date"
                      placeholder="请选择"
                      v-model="filterForm.startTime"
                      style="width: 100%"
                      value-format="timestamp"
                    ></el-date-picker>
                  </el-form-item>
                  <el-form-item label="结束时间">
                    <el-date-picker
                      type="date"
                      placeholder="请选择"
                      v-model="filterForm.endTime"
                      style="width: 100%"
                      value-format="timestamp"
                    ></el-date-picker>
                  </el-form-item>
                  <el-form-item label="金额警戒线">
                    <el-input v-model="filterForm.moneyCount" placeholder="请输入"></el-input>
                  </el-form-item>
                  <el-form-item label="交易次数警戒线">
                    <el-input v-model="filterForm.tradeCount" placeholder="请输入"></el-input>
                  </el-form-item>
                  <el-form-item label-width="70px">
                    <el-button type="primary" @click="filterSubmit()">标注</el-button>
                    <el-button @click="resetFilter()">清空</el-button>
                  </el-form-item>
                </el-form>
                <img slot="reference" src="@/assets/chart/icon-chart-filter.png" />
              </el-popover>

              <div class="control">
                <div class="control-top">
                  <img src="@/assets/chart/icon-chart-large.png" @click="zoomBtn(0.1)" />
                  <img src="@/assets/chart/icon-chart-small.png" @click="zoomBtn(-0.1)" />
                  <img src="@/assets/chart/icon-chart-center.png" @click="centBtn()" />
                  <img src="@/assets/chart/icon-chart-fullscreen.png" @click="fullScreen()" />
                  <img src="@/assets/chart/icon-chart-download.png" @click="download()" />
                  <img src="@/assets/chart/icon-chart-jys.png" @click="downloadExchange" />
                </div>
                <div class="control-bottom">
                  <img src="@/assets/chart/icon-chart-save.png" @click="saveRelation()" />
                </div>
              </div>

              <div class="legend" v-if="showLegend">
                <div class="legend-item">
                  <div class="legend-item-line" style="background-color: #409eff"></div>
                  <div class="legend-item-txt">时间</div>
                </div>
                <div class="legend-item">
                  <div class="legend-item-line" style="background-color: #ea3224"></div>
                  <div class="legend-item-txt">金额</div>
                </div>
                <div class="legend-item">
                  <div class="legend-item-line" style="background-color: #f7e34c"></div>
                  <div class="legend-item-txt">频率</div>
                </div>
              </div>
              <!-- 等待页面 -->
              <div class="waiting" v-if="showWating">大数据分析扫描中</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getRelDetail,
  getAddrRelation,
  getAddrInfo,
  getLabelList,
  addLabel,
  relationInit,
  getRelationCount,
  getRelationAll,
  getSavedRelation,
  saveRelation,
  downExchange
} from "@/api/relation";
import defaultOptions from "./defaultOptions.json";
import RelationSearch from "./components/RelationSearch";
import RelationTitle from "./components/RelationTitle";
import RelationInfo from "./components/RelationInfo";
import { api as fullscreen } from "vue-fullscreen";

import { reloadNodes, getMoreNode, filterNode,filterLabelNode } from "./nodeUtil";

import html2canvas from "html2canvas";
import ProjList from "./components/ProjList";
import Monitor from "@/views/monitor/index";

export default {
  name: "relation-chart",
  components: {
    RelationSearch,
    RelationTitle,
    RelationInfo,
    ProjList,
    Monitor,
  },
  data() {
    return {
      curTab: 0,

      showSearch: true,
      showWating: false,
      showEdit: false,
      popType: 1,
      showEdgeEdit: false,
      projDetail: {},
      relationData: [],
      relId: null,
      graph: null,
      filters: {
        token: null,
      },
      labelList: [],
      relationTempData: {}, //用于存储每个节点的流入流程关系
      zoom: 100,
      limit: 5,
      tokenType: "",

      curNode: {
        source: "",
        target: "",
      },
      curNodeList:[],
      defLabel: "",
      addrInfo: {
        addr: "",
      },
      tradeTotal: 0,
      tradePageNo: 1,
      tradePageSize: 1,
      tradeList: [],
      curLayout: 1,
      layoutOptions: [
        {
          value: 1,
          label: "辐射图",
        },
        {
          value: 2,
          label: "力导向图",
        },
      ],
      relSearchHeight: 0,
      origin: {
        height: 0,
        width: 0,
        isFullscreen: false,
      },
      filterForm: {
        startTime: null,
        endTime: null,
        moneyCount: null,
        tradeCount: null,
      },
      showLegend: false,
      showPopover: false,
      showProjList: true,

      curProj: null,

      showRemark:false
    };
  },
  mounted() {
    this.getLabelList();
  },
  methods: {
    // 页面返回
    goBack() {
      this.$router.go(-1);
    },
    // 初始化图谱数据接口
    relationInit(data) {
      //设置节点展开数
      this.limit = data.maxNextTxs;
      this.tokenType = data.tokenCode;

      this.relationTempData = {};
      if (this.showWating) {
        this.$message.error("图谱分析中，请稍后再试");
        return;
      }
      this.showWating = true;
      relationInit(data)
        .then((res) => {
          if(res.data.code=="9991"){
            this.$message.error(res.data.message)
            this.showWating = false;
            return
          }
          let resultData = res.data.data;

          //转换得到的接口数据
          this.relationData = reloadNodes(resultData, data.addrList);
          //拼接更多地址按钮节点
          resultData.vertex.forEach((item) => {
            let addr = item.address;
            // 更多地址按钮生成
            if (item.remainderFromCount > 0 && data.from) {
              //流入更多
              this.relationData.nodes.push({
                size: [34, 33],
                label: `更多地址(${item.remainderFromCount}个)`,
                id: "more" + addr + 0,
                img: "./img/icon-chart-add.png",
                type: "image",
                target: addr,
                moneyType: 0,
                tokenType: this.tokenType,
                isMore: true,
                offset: this.limit,
              });
              this.relationData.edges.push({
                target: addr,
                source: "more" + addr + 0,
                isMore: true,
                style: {
                  stroke: "#1771D6",
                  endArrow: true,
                },
              });
            }

            if (item.remainderToCount > 0 && data.to) {
              //流出更多
              this.relationData.nodes.push({
                size: [34, 33],
                label: `更多地址(${item.remainderToCount}个)`,
                id: "more" + addr + 1,
                img: "./img/icon-chart-add.png",
                type: "image",
                target: addr,
                moneyType: 1,
                tokenType: this.tokenType,
                isMore: true,
                offset: this.limit,
              });
              this.relationData.edges.push({
                target: "more" + addr + 1,
                source: addr,
                isMore: true,
                style: {
                  stroke: "#8DC21F",
                  endArrow: true,
                },
              });
            }
          });

          this.relationData = filterNode(this.relationData, this.filterForm);

          //初始化图，如图存在则更新视图数据
          if (this.graph) {
            this.graph.changeData(this.relationData);
            this.graph.updateLayout({
              maxIteration: 500,
            });
            this.graph.refresh();
          } else {
            this.updateChart(500);
          }

          setTimeout(() => {
            this.showWating = false;
          }, 500);
        })
        .catch((err) => {
          if(err.message.indexOf("timeout")>-1){
            this.$message.error("图谱加载超时，请稍后重试")
          }
          this.relationData = []
          this.graph.data(this.relationData); // 读取数据源到图上
          this.graph.render();
          this.showWating = false;
        });
    },
    // 获取关系详情
    getRelDetail() {
      getRelDetail(this.relId).then((res) => {
        this.projDetail = res.data.data;
        this.tokenType = this.projDetail.tokenCode || "ETH";

        this.$refs.relSearch.setTokenCode(this.tokenType);


        if(this.projDetail.layer==null && this.projDetail.maxNextTxs==null){
          this.$refs.relSearch.resetSearch();
        }else{
          this.$refs.relSearch.filters.layer = this.projDetail.layer;
          this.$refs.relSearch.filters.maxNextTxs = this.projDetail.maxNextTxs;
          this.limit = this.projDetail.maxNextTxs
          
          if(this.projDetail.startDate==null){
            this.$refs.relSearch.daterange = null;
          }else{
            this.$refs.relSearch.daterange = [this.projDetail.startDate*1000,this.projDetail.endDate*1000];
          }
        }


        this.getSavedRelation(this.relId, this.tokenType);
      });
    },
    // 获取用户保存的图谱
    getSavedRelation(relId, tokenCode) {
      let that = this;
      this.showWating = true;
      getSavedRelation(relId, tokenCode)
        .then((res) => {
          if (res.data.data == null) {
            this.relationData = [];
            if (this.graph) this.graph.clear();
          }

          if (res.data.data != null) {
            this.filterForm.startTime = res.data.data.startDate;
            this.filterForm.endTime = res.data.data.endDate;
            this.filterForm.moneyCount = res.data.data.maxAmount;
            this.filterForm.tradeCount = res.data.data.txnTimes;

            if (
              this.filterForm.startTime ||
              this.filterForm.moneyCount ||
              this.filterForm.tradeCount
            ) {
              this.showLegend = true;
            } else {
              this.showLegend = false;
            }

            let resultData = {
              vertex: res.data.data.vertexs,
              edge: res.data.data.edges,
            };

            //转换得到的接口数据
            this.relationData = reloadNodes(resultData, that.projDetail.addrs);
            //拼接更多地址按钮节点
            resultData.vertex.forEach((item) => {
              let addr = item.address;
              // 更多地址按钮生成
              if (item.remainderFromCount > 0) {
                //流入更多
                this.relationData.nodes.push({
                  size: [34, 33],
                  label: `更多地址(${item.remainderFromCount}个)`,
                  id: "more" + addr + 0,
                  img: "./img/icon-chart-add.png",
                  type: "image",
                  target: addr,
                  moneyType: 0,
                  tokenType: this.tokenType,
                  isMore: true,
                  offset: this.limit,
                });
                this.relationData.edges.push({
                  target: addr,
                  source: "more" + addr + 0,
                  isMore: true,
                  style: {
                    stroke: "#1771D6",
                    endArrow: true,
                  },
                });
              }

              if (item.remainderToCount > 0) {
                //流出更多
                this.relationData.nodes.push({
                  size: [34, 33],
                  label: `更多地址(${item.remainderToCount}个)`,
                  id: "more" + addr + 1,
                  img: "./img/icon-chart-add.png",
                  type: "image",
                  target: addr,
                  moneyType: 1,
                  tokenType: this.tokenType,
                  isMore: true,
                  offset: this.limit,
                });
                this.relationData.edges.push({
                  target: "more" + addr + 1,
                  source: addr,
                  isMore: true,
                  style: {
                    stroke: "#8DC21F",
                    endArrow: true,
                  },
                });
              }
            });

            this.relationData = filterNode(this.relationData, this.filterForm);

            //初始化图，如图存在则更新视图数据
            if (this.graph) {
              this.graph.changeData(this.relationData);
            } else {
              if(res.data.data.isSave)
                this.updateChart(500);
              else
                this.updateChart(500);
            }
          }

          setTimeout(() => {
            this.showWating = false;
          }, 500);
        })
        .catch((err) => {
          this.showWating = false;
          if (this.graph) this.graph.clear();
          this.$message.error(err)
        });
    },
    // 获取标签列表
    getLabelList() {
      getLabelList().then((res) => {
        this.labelList = res.data.data;
      });
    },
    updateChart(maxIteration) {
      // const toolbar = new G6.ToolBar();
      const grid = new G6.Grid();

      const contextMenu = new G6.Menu({
        getContent(evt) {
          console.log(evt)
          if(evt.item._cfg.model.isMore){
            return null
          }
          return `<b>添加备注</b>`;
        },
        handleMenuClick: (target, item) => {
          console.log(target, item);
          this.showRemarkDid(target, item)
        },
        // offsetX and offsetY include the padding of the parent container
        // 需要加上父级容器的 padding-left 16 与自身偏移量 10
        offsetX: 16 + 10,
        // 需要加上父级容器的 padding-top 24 、画布兄弟元素高度、与自身偏移量 10
        offsetY: 0,
        // the types of items that allow the menu show up
        // 在哪些类型的元素上响应
        itemTypes: ['node', 'edge'],
      });

      this.graph = new G6.Graph({
        container: "mountNode",
        ...defaultOptions,
        plugins: [grid,contextMenu],
        layout: {
          type: "gForce",
          preventOverlap: true,
          gravity: 10,
          edgeStrength: 100,
          nodeStrength: 200,
          coulombDisScale: 0.001,
          maxIteration: maxIteration,
        },
      });

      this.addGraphListener();

      this.graph.data(this.relationData); // 读取数据源到图上
      this.graph.render(); // 渲染图
    },
    // 分页查找地址
    getAddrRelation(type,way=1) {
      let offset = 10
      
      if (this.curNodeList.length<=0) {
        this.$message.error("请选择想要查询的地址");
        return;
      }
      let addressSet = this.curNodeList.map(ele=>{
        let node = ele.getModel();
        return node.id
      })

      if(way==2){
        addressSet = this.curNode.isMore ? [this.curNode.target] : [this.curNode.id];
        offset = this.curNode.offset ? this.curNode.offset : 0;
        debugger
      }
      
      this.showWating = true;
      getAddrRelation(addressSet, type, offset, this.limit, this.tokenType)
        .then((res) => {
          let data = res.data.data
          data.forEach(ele=>{
            let addr = ele.address
            
            let resultData = reloadNodes(ele);

            // 还有更多节点时
            // 删除更多按钮
            this.relationData.nodes.forEach((item, index) => {
              if (item.id == "more" + addr + type) {
                this.relationData.nodes.splice(index, 1);
              }
            });
            this.relationData.edges.forEach((item, index) => {
              if (item.target == "more" + addr + type || item.source == "more" + addr + type) {
                this.relationData.edges.splice(index, 1);
              }
            });


            if (ele.remainderCount > 0) {
              offset = parseInt(offset) + parseInt(this.limit);
              resultData.nodes.push({
                size: [34, 33],
                label: `更多地址(${ele.remainderCount}个)`,
                id: "more" + addr + type,
                img: "./img/icon-chart-add.png",
                type: "image",
                target: addr,
                moneyType: type,
                tokenType: this.tokenType,
                isMore: true,
                offset: offset,
              });
              let edge = {
                isMore: true,
                style: {
                  stroke: type == 0 ? "#1771D6" : "#8DC21F",
                },
              };
              if (type == 0) {
                edge.target = addr;
                edge.source = "more" + addr + type;
              } else {
                edge.target = "more" + addr + type;
                edge.source = addr;
              }
              resultData.edges.push(edge);
            }

            this.relationData.nodes = this.relationData.nodes.concat(resultData.nodes);
            this.relationData.edges = this.relationData.edges.concat(resultData.edges);

            this.relationData = filterNode(this.relationData, this.filterForm);
          })

          // if (resultData.nodes.length <= 0 && resultData.edges.length <= 0) {
          //   setTimeout(() => {
          //     this.showWating = false;
          //   }, 500);
          //   return;
          // }

          this.graph.changeData(this.relationData);
          this.graph.refresh();

          setTimeout(() => {
            this.showWating = false;
          }, 500);
        })
        .catch((err) => {
          this.showWating = false;
        });
    },
    //查询全部交易数量，用于提示用户展开
    getRelationCount() {
      
      if (this.curNodeList.length<=0) {
        this.$message.error("请选择想要查询的地址");
        return;
      }
      let addressSet = this.curNodeList.map(ele=>{
        let node = ele.getModel();
        return node.id
      })
          
      this.getRelationAll(addressSet, this.tokenType);
    },
    //查询全部交易对手
    getRelationAll(addressSet, tokenType) {
      this.showWating = true;
      getRelationAll(addressSet, tokenType).then((res) => {
        let resultData = reloadNodes(res.data.data);

        this.relationData.nodes = this.relationData.nodes.concat(resultData.nodes);
        this.relationData.edges = this.relationData.edges.concat(resultData.edges);

        // 还有更多节点时
        // 删除更多按钮
        addressSet.forEach(addr=>{
          this.relationData.nodes.forEach((item, index) => {
            if (item.id == "more" + addr + 0 || item.id == "more" + addr + 1) {
              this.relationData.nodes.splice(index, 1);
            }
          });
          this.relationData.edges.forEach((item, index) => {
            if (
              item.target == "more" + addr + 0 ||
              item.source == "more" + addr + 0 ||
              item.target == "more" + addr + 1 ||
              item.source == "more" + addr + 1
            ) {
              this.relationData.edges.splice(index, 1);
            }
          });
        })

        this.relationData = filterNode(this.relationData, this.filterForm);
        this.graph.changeData(this.relationData);
        this.graph.refresh();

        setTimeout(() => {
          this.showWating = false;
        }, 500);
      });
    },
    // 查看交易所功能回调
    getLabelRelation(){
      let data = filterLabelNode(this.relationData);
      if(data){
        this.relationData = data;
        this.graph.changeData(this.relationData);
        this.graph.refresh();
      }else{
        this.$message.warning("暂无交易所") 
      }
    },
    // 删除节点按钮回调
    delRelation() {

      if (this.curNodeList.length<=0) {
        this.$message.error("请选择想要删除的地址");
        return;
      }
      let curIds = this.curNodeList.map(ele=>{
        let node = ele.getModel();
        return node.id
      })

      curIds.forEach(ele=>{
        let delId = []; //记录需要删除的点的id，闭目两个节点间有多条线的情况
        //删除选中节点
        this.graph.removeItem(ele);

        this.relationData.nodes = this.relationData.nodes.filter((item) => {
          return ele != item.id;
        });
        this.relationData.edges = this.relationData.edges.filter((item) => {
          return ele != item.target && ele != item.source;
        });

        let id = this.projDetail.addrs;

        let nodes = this.graph.getNodes();
        nodes.forEach((ele) => {
          if (id != ele.getModel().id) {
            let path = G6.Algorithm.findShortestPath(this.relationData, id, ele.getModel().id);
            if (path.path == undefined) {
              delId.push(ele.getModel().id);
            }
          }
        });

        // 统一删除所有节点
        delId.forEach((id) => {
          this.graph.removeItem(id);
          this.relationData.nodes = this.relationData.nodes.filter((item) => {
            return id != item.id;
          });
          this.relationData.edges = this.relationData.edges.filter((item) => {
            return id != item.target && id != item.source;
          });
        });

      })
      //删除选中节点
      // this.graph.removeItem(this.curNode.id);

      // this.relationData.nodes = this.relationData.nodes.filter((item) => {
      //   return curId != item.id;
      // });
      // this.relationData.edges = this.relationData.edges.filter((item) => {
      //   return curId != item.target && curId != item.source;
      // });

      // let id = this.projDetail.addrs[0];

      // let nodes = this.graph.getNodes();
      // nodes.forEach((ele) => {
      //   if (id != ele.getModel().id) {
      //     let path = G6.Algorithm.findShortestPath(this.relationData, id, ele.getModel().id);
      //     if (path.path == undefined) {
      //       delId.push(ele.getModel().id);
      //     }
      //   }
      // });

      // // 统一删除所有节点
      // delId.forEach((id) => {
      //   this.graph.removeItem(id);
      //   this.relationData.nodes = this.relationData.nodes.filter((item) => {
      //     return id != item.id;
      //   });
      //   this.relationData.edges = this.relationData.edges.filter((item) => {
      //     return id != item.target && id != item.source;
      //   });
      // });
    },
    // 查询地址详情
    getAddrInfo(id, token) {
      getAddrInfo(id, token).then((res) => {
        this.addrInfo = res.data.data;
        this.popType = 1;
        this.showEditBtn();
      });
    },
    /******************* 视图控件回调方法 *******************/
    // 缩放按钮回调
    zoomBtn(value) {
      this.graph.zoomTo(this.graph.getZoom() + value);
      this.zoom = (this.graph.getZoom() * 100).toFixed(0);
    },
    // 定位按钮回调
    centBtn() {
      this.graph.fitCenter();
      this.zoom = (this.graph.getZoom() * 100).toFixed(0);
    },
    // 全屏按钮回调
    fullScreen() {
      // if (this.showEdit) {
      //   this.closeEditBtn();
      // }

      console.log(this.$refs.chart.offsetHeight, this.$refs.chart.offsetWidth);
      if (!this.origin.isFullscreen && this.origin.height == 0) {
        this.origin.height = this.$refs.chart.offsetHeight - 6;
        this.origin.width = this.$refs.chart.offsetWidth - 2;
      }
      fullscreen.toggle(this.$refs.chart, {
        teleport: true,
        callback: (isFullscreen) => {
          if (isFullscreen) {
            console.log(this.$refs.chart.offsetHeight, this.$refs.chart.offsetWidth);
            this.origin.isFullscreen = true;
            this.graph.changeSize(this.$refs.chart.offsetWidth, this.$refs.chart.offsetHeight);
          } else {
            this.origin.isFullscreen = false;
            this.graph.changeSize(this.origin.width, this.origin.height);
          }
          this.graph.fitCenter();
        },
      });
    },
    // 下载按钮回调
    download() {
      // this.graph.downloadFullImage("关系图谱", "image/png", {
      //   backgroundColor: "#fff",
      // });

      html2canvas(this.$refs.mountNode).then((canvas) => {
        const url = canvas.toDataURL(`image/png`);
        const imgEle = new Image();
        imgEle.src = url;
        imgEle.onload = () => {
          const a = document.createElement("a");
          a.href = url;
          a.download = "关系图谱.png";
          // 触发a链接点击事件，浏览器开始下载文件
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        };
      });
    },
    // 下载excel回调
    downloadExchange(){
      let vertices = []
      this.relationData.nodes.forEach(ele => {
        if(ele.extraData)
          vertices.push(ele.extraData) 
      });
      let edges = []
      this.relationData.edges.forEach(ele=>{
        if(ele.extraData)
          edges.push(ele.extraData) 
      })
      downExchange(this.tokenType,vertices,edges)
    },
    // 保存按钮
    saveRelation() {
      if(this.relationData.length<=0){
        this.$message.error("暂无可保存的图谱")
        return
      }
      let vertexs = [];
      this.relationData.nodes.forEach((ele) => {
        if (ele != null && ele.extraData) {
          vertexs.push({
            x: ele.x,
            y: ele.y,
            address: ele.extraData.address,
            tag: ele.extraData.tag,
            tagLogoUrl: ele.extraData.tagLogoUrl,
            color: ele.style.stroke,
            shape: ele.extraData.shape,
            layer: ele.extraData.layer,
            text: ele.extraData.text,
            remark: ele.extraData.remark,
            remainderFromCount: ele.extraData.remainderFromCount,
            remainderToCount: ele.extraData.remainderToCount,
            isLabel: ele.extraData.isLabel,
          });
        }
      });

      let edges = [];
      this.relationData.edges.forEach((ele) => {
        if (ele != null && ele.extraData) {
          edges.push({
            txhash: ele.extraData.txhash,
            logIndex: ele.extraData.logIndex,
            from: ele.extraData.from,
            to: ele.extraData.to,
            totalValue: ele.extraData.totalValue,
            totalAmount: ele.extraData.totalAmount,
            txCount: ele.extraData.txCount,
            color: ele.style.stroke,
            time: ele.extraData.time,
            minTime: ele.extraData.minTime,
            maxTime: ele.extraData.maxTime,
            symbol: ele.extraData.symbol,
            remark: ele.extraData.remark,
            type: ele.extraData.type,
            isLabel: ele.extraData.isLabel,
            hash:ele.extraData.hash
          });
        }
      });

      let data = {
        vertexs,
        edges,
        relId: this.relId,
        tokenCode: this.tokenType,
        minAmount: null,
        maxAmount: this.filterForm.moneyCount,
        startDate: this.filterForm.startTime,
        endDate: this.filterForm.endTime,
        txnTimes: this.filterForm.tradeCount,
        dateColor: "#409eff",
        amountColor: "#EA3224",
        timesColor: "#F7E34C",
      };

      saveRelation(data).then((res) => {
        if (res.data.code == "0") {
          this.$message.success("图谱保存成功！");
        }
      });
    },
    // 更新布局回调
    layoutChanged(val) {
      if (val == 1) {
        this.graph.destroyLayout();
        this.graph.updateLayout({
          type: "radial",
          unitRadius: 200,
          nodeSize: 100,
          nodeSpacing: 50,
          preventOverlap: true,
          strictRadial: true,
          sortBy: "data",
        });
      } else {
        this.graph.updateLayout({
          type: "force",
          preventOverlap: true,
        });
      }
    },
    // 标注按钮点击回调
    filterSubmit() {
      if (this.filterForm.startTime > this.filterForm.endTime) {
        this.$message.error("开始时间不能大于结束时间");
        return;
      }

      if (this.filterForm.startTime || this.filterForm.moneyCount || this.filterForm.tradeCount) {
        this.showLegend = true;
      } else {
        this.showLegend = false;
      }
      this.relationData = filterNode(this.relationData, this.filterForm);
      this.graph.changeData(this.relationData);
      this.graph.refresh();
      this.showPopover = false;
    },
    resetFilter() {
      this.filterForm = {
        startTime: null,
        endTime: null,
        moneyCount: null,
        tradeCount: null,
      };
      this.showLegend = false;
      this.relationData = filterNode(this.relationData, this.filterForm);
      this.graph.changeData(this.relationData);
      this.graph.refresh();
    },
    /******************* 编辑控件回调方法 *******************/
    // 编辑视图折叠按钮回调
    showEditBtn() {
      this.$refs.chartLeft.style.width = 320 + "px";

      setTimeout(() => {
        this.showEdit = true;
      }, 650);
    },
    closeEditBtn() {
      this.$refs.chartLeft.style.width = 0 + "px";
      this.showEdit = false;
    },
    // 标签修改回调
    tabChanged(e) {
      console.log(e);
      this.curNode.label = e;
    },
    // 自定义标签按钮回调
    addLabel() {
      if (this.defLabel == "") {
        this.$message.error("请输入自定义标签名");
        return;
      }
      addLabel(this.defLabel).then((res) => {
        this.getLabelList();
        this.curNode.label = this.defLabel;
        this.defLabel = "";
      });
    },
    /******************* 搜索回调方法 *******************/
    searchClick(filters) {
      let address = filters.address;
      if (address.length<10) {
        this.$message.error("请输入合法地址");
        return;
      }
      this.filterForm = {
        startTime: null,
        endTime: null,
        moneyCount: null,
        tradeCount: null,
      };
      // 清理交易弹窗
      if (this.showEdit) {
        this.$refs.relInfo.clearInfo()
        this.closeEditBtn();
        this.addrInfo = {
          addr: "",
        };
      }
      this.limit = Number(filters.maxNextTxs)
      this.relationInit(filters);
    },
    /******************* graph listener *******************/
    addGraphListener() {
      //  节点点击，记录
      this.graph.on("node:click", (ev) => {
        const { item } = ev;
        // console.log(item.getModel());
        this.curNode = item.getModel();
        if (this.curNode.isMore) {
          this.getAddrRelation(this.curNode.moneyType,2);
        } else {
          if (this.curNode.extraData.tag) {
            return;
          }
          this.graph.focusItem(item, true);
          this.getAddrInfo(this.curNode.id, this.tokenType);
        }
      });
      //  连线点击，记录
      this.graph.on("edge:click", (ev) => {
        const { item } = ev;
        this.curNode = item.getModel();
        this.popType = 2;
        this.$refs.relInfo.update(this.curNode)
        this.showEditBtn();
      });
      // 鼠标滑过高亮
      this.graph.on("afteractivaterelations", (e) => {
        let curNode = e.item;
        let neighbors = this.graph.getNeighbors(curNode.getModel().id);

        if (e.action == "activate") {
          this.graph.setItemState(curNode, "hover", true);
          neighbors.forEach((ele) => {
            this.graph.setItemState(ele, "hover", true);
          });
        } else {
          this.graph.setItemState(curNode, "hover", false);
          neighbors.forEach((ele) => {
            this.graph.setItemState(ele, "hover", false);
          });
        }
      });
      // 鼠标滚轮回调
      this.graph.on("wheelzoom", (e) => {
        this.zoom = (this.graph.getZoom() * 100).toFixed(0);
      });

      function refreshDragedNodePosition(e) {
        const model = e.item.get("model");
        model.fx = e.x;
        model.fy = e.y;
        model.x = e.x;
        model.y = e.y;
      }

      this.graph.on("node:dragstart", (e) => {
        // 拖动节点时重新布局
        this.graph.layout();
        refreshDragedNodePosition(e);
      });

      this.graph.on("node:drag", (e) => {
        const forceLayout = this.graph.get('layoutController').layoutMethods[0];
        forceLayout.execute();
        refreshDragedNodePosition(e);
      });

      // this.graph.on('node:dragend', (e) => {
      //   e.item.get('model').fx = null;
      //   e.item.get('model').fy = null;
      // });
      
      // 多选节点回调
      this.graph.on("nodeselectchange", (e) => {
        this.curNodeList = e.selectedItems.nodes
      });
    },

    // 项目列表相关方法
    // 左侧选择图谱回调
    relSelectd(rel) {
      this.relId = rel.id;
      this.curTab = 0;
      this.getRelDetail();
    },
    projSelectd(proj) {
      this.curProj = proj;
    },
    showProjListChanged(isShow) {
      if (isShow) {
        this.$refs.relationChartLeft.style.width = 260 + "px";

        if (this.graph) {
          let width = this.graph.getWidth();
          let height = this.graph.getHeight();
          this.graph.changeSize(width - 260, height);
          this.graph.translate(-260, 0);
        }
      } else {
        this.$refs.relationChartLeft.style.width = 0 + "px";

        if (this.graph) {
          let width = this.graph.getWidth();
          let height = this.graph.getHeight();
          this.graph.changeSize(width + 260, height);
          this.graph.translate(260, 0);
        }
      }

      setTimeout(() => {
        this.showProjList = isShow;
      }, 600);
    },
    curTabChanged(val) {
      this.curTab = val;
    },
    /* 备注 */
    showRemarkDid(target, item){
      // this.showRemark = true
      this.$prompt('请输入备注内容', '添加备注', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }).then(({ value }) => {
          this.$message({
            type: 'success',
            message: '备注添加成功！'
          });
          item._cfg.model.extraData.remark = value
          this.saveRelation();
          setTimeout(() => {
            this.getRelDetail();
          }, 100);
        })
    }
  },
};
</script>

<style lang="scss" scoped>
.relation-chart {
  flex: 1;
  display: flex;
  border-top: 1px solid #f2f2f2;
  .relation-chart-left {
    width: 260px;
    background-color: #fafafa;
    display: flex;
    flex-direction: column;
    position: relative;
    transition: width 0.6s linear 0.1s;
    .img-left {
      position: absolute;
      right: -20px;
      top: 12px;
      height: 40px;
      width: 40px;
      cursor: pointer;
    }
  }
  .relation-chart-right {
    flex: 1;
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: #fff;
    .img-right {
      position: absolute;
      left: 20px;
      top: 12px;
      height: 40px;
      width: 40px;
      cursor: pointer;
    }
    .tabs {
      display: flex;
      width: 120px;
      justify-content: space-between;
      margin-left: 30px;
      margin-top: 50px;
      margin-bottom: 24px;
      .tab-item {
        height: 25px;
        line-height: 25px;
        font-size: 18px;
        font-family: PingFangSC-Medium;
        cursor: pointer;
      }
      .tab-item-active {
        color: #2e43eb;
      }
      .tab-item-active::after {
        content: "";
        width: 13px;
        height: 2px;
        background-color: #2e43eb;
        font-family: PingFangSC-Medium;
        display: block;
        margin: 0 auto;
      }
    }
  }

  .relation-chart-top {
    box-sizing: border-box;
    display: flex;
    padding: 0 30px;
    flex-direction: column;
  }
}

.relation-chart-bottom {
  display: flex;
  box-sizing: border-box;
  padding: 30px;
  flex: 1;
  overflow: hidden;
}

.fullscreen-container {
  display: flex;
  flex: 1;
  border-radius: 4px;
  border: 1px solid #eaeaea;
  box-sizing: border-box;
  position: relative;
  .left {
    width: 0;
    height: 100%;
    background-color: #fff;
    border-radius: 10px;
    border-right: 1px solid #eaeaea;
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 80;
    transition: width 0.6s linear 0.1s;
  }

  .right {
    flex: 1;
    border-radius: 10px;
    display: flex;
  }
}

.chart {
  background-color: #fff;
  position: relative;
  flex: 1;
  box-sizing: border-box;

  .left-show-btn {
    position: absolute;
    top: 27px;
    left: 0px;
    height: 39px;
    width: 68px;
    cursor: pointer;
  }

  .chart-right-edit {
    position: absolute;
    top: 40px;
    right: 30px;
    height: 90px;
    width: 120px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    /deep/.el-button {
      width: 100%;
      margin: 0;
    }
  }

  .mountNode {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    background: url("../../assets/chart/logo2.png") no-repeat;
    background-position: center;
    position: relative;
  }

  .waiting {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    top: 0;
    left: 0;
  }
}

.control {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 150px;
  right: 55px;

  .control-top {
    display: flex;
    flex-direction: column;
    background: rgba(239, 239, 239, 0.44);
    box-shadow: 0px 0px 4px 0px rgba(210, 210, 210, 0.5);
    border-radius: 4px;
    padding: 20px 5px 0 5px;
    img {
      cursor: pointer;
      height: 24px;
      width: 24px;
      margin-bottom: 24px;
    }
  }

  .control-bottom {
    display: flex;
    flex-direction: column;
    margin-top: 16px;
    background: rgba(239, 239, 239, 0.44);
    box-shadow: 0px 0px 4px 0px rgba(210, 210, 210, 0.5);
    border-radius: 4px;
    padding: 5px;
    img {
      cursor: pointer;
      height: 24px;
      width: 24px;
    }
  }
}

.legend {
  display: flex;
  flex-direction: column;
  height: 80px;
  width: 100px;
  bottom: 30px;
  right: 40px;
  border-radius: 5px;
  padding: 10px;
  position: absolute;
  box-shadow: 0px 20px 40px 0px rgba(23, 128, 224, 0.17);
  z-index: 999;

  .legend-item {
    display: flex;
    justify-content: space-between;
    flex: 1;
    align-items: center;

    .legend-item-line {
      width: 50px;
      height: 5px;
    }

    .legend-item-txt {
      font-size: 14px;
    }
  }
}

.display {
  position: absolute;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
  // width: 100%;
  display: flex;
  justify-content: center;

  &-container {
    height: 65px;
    min-width: 524px;
    // background: #f9f9f9;
    // border: 1px solid #f1efef;
    border-radius: 34px;
    display: flex;
    justify-content: space-evenly;

    &-item {
      font-size: 11px;
      height: 38px;
      display: flex;
      align-items: center;
      cursor: pointer;
      color: #202038;
      background-color: #eaf2ff;
      border-radius: 3px;
      padding: 0 12px;
      font-family: PingFangSC-Medium;
      margin-right: 16px;
      img {
        height: 25px;
        width: 25px;
        margin-right: 4px;
      }
    }

    &-item:last-child {
      margin-right: 0 !important;
    }
  }
}

.filter {
  position: absolute;
  top: 25px;
  right: 47px;
  cursor: pointer;

  img {
    height: 44px;
    width: 45px;
  }
}
</style>

<style lang="scss">
.bit-select-item {
  height: 34px;
  line-height: 34px;
  display: flex;
  align-items: center;

  &-name {
    height: 34px;
    line-height: 34px;
    margin-left: 8px;
  }
}
</style>
