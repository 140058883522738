/**
 * 重新组装节点数据
 * 
 * @params {originDatas } 原始数据
 * @params {addrList } 初始化地址列表
 */
export const reloadNodes = function (originDatas, addrList) {
    if (originDatas.vertex.length <= 0 && originDatas.edge.length <= 0) {
        return {
            nodes: [],
            edges: []
        }
    }

    let nodes = originDatas.vertex.map((item, index) => {
        let label = `${item.address.substring(0, 3)}...${item.address.substring(
            item.address.length - 3,
            item.address.length
        )}`
        if (item.tag) {
            label += '\n' + item.tag
        }
        if(item.remark){
            label += '\n' + item.remark
        }

        let tagLogoUrl = null;
        let nodeType = 'circle'
        let nodeStyle = {
            fill: item.color,
            stroke: item.color,
        }
        // 如果有图片
        if(item.tagLogoUrl){
            tagLogoUrl = `./img/${item.tagLogoUrl}.png`
            nodeType = 'image'
            nodeStyle = {
                fill: null,
                stroke: null,
            }
        }
        console.log(tagLogoUrl)

        let node = {
            size: [34, 33],
            label: label,
            id: item.address,
            layer: 1,
            style: nodeStyle,
            img: tagLogoUrl,
            type: nodeType,
            extraData: item,

        }
        if(item.x!=null){
            node.x = item.x
            node.fx = item.x
        }
        if(item.y!=null){
            node.y = item.y
            node.fy = item.y
        }
        return node;
    });

    let edges = originDatas.edge.map((item) => {
        let label = `  ${item.totalAmount}$ ${item.txCount}笔  `;
        if(item.remark){
            label += '\n' + item.remark
        }

        return {
            source: item.from,
            target: item.to,
            label: label,
            style: {
                stroke: item.color,
                endArrow: true,
                cursor: "pointer",
                lineWidth:item.txCount/10
            },
            type:"quadratic",
            extraData: item
        };
    });
    let resultData = {
        nodes,
        edges
    }

    return resultData
}



/**
 * 组装原数据和临时数据
 * 
 * @params {relationData } 图的原数据
 * @params {relationTempData } 待拼装数据
 */
export const getMoreNode = function (addr, type, remainderFromCount, limit) {

    let resultData = {
        node: {},
        edge: {}
    };

    resultData.node = {
        size: 32,
        label: `更多地址(${remainderFromCount}个)`,
        id: "more" + addr + type,
        style: {
            fill: "rgb(138,212,223)",
            stroke: "rgb(138,212,223)",
        },
        target: addr,
        moneyType: type,
        isMore: true,
        offset: limit,
    }


    let edge = {};
    if (type == 0) {
        edge.source = "more" + addr + type;
        edge.target = addr;
    } else {
        edge.source = addr;
        edge.target = "more" + addr + type;
    }

    resultData.edge = edge

    return resultData
}
/**
 * 渲染数据去重
 * 
 * @params {relationData } 图的原数据
 * @params {filterForm } 标注数据
 */
export const filterNode = function (relationData, filterForm) {

    let obj = {}
    let nodes = relationData.nodes.reduce((resultList, item) => {
        obj[item.id] ? '' : (obj[item.id] = true && resultList.push(item))
        return resultList;
    }, []);

    let edges = relationData.edges.reduce((resultList, item) => {
        let key = item.source + item.target
        obj[key] ? '' : (obj[key] = true && resultList.push(item))
        return resultList;
    }, []);


    // 
    edges.forEach(element => {

        edges.forEach(item => {
            if (element.target == item.source && element.source == item.target) {
                item.type = "quadratic"
                element.sourceAnchor = 1
                element.targetAnchor = 5
                item.sourceAnchor = 5
                item.targetAnchor = 1
            }
        })
        

        if (element.extraData) {
            element.style.stroke = element.extraData.color
            if (filterForm.tradeCount != null && filterForm.tradeCount != "") {

                if (element.extraData.txCount >= filterForm.tradeCount) {
                    element.style.stroke = "rgb(247,227,76)"
                }
            }

            if (filterForm.moneyCount != null&&filterForm.moneyCount != "") {
                if (parseFloat(element.extraData.totalAmount) >= filterForm.moneyCount) {
                    element.style.stroke = "rgb(234,50,36)"
                }
            }
            
            if(filterForm.startTime != null && filterForm.endTime != null && filterForm.startTime != "" && filterForm.endTime != ""){
                if( element.extraData.minTime >= filterForm.startTime/1000 && element.extraData.maxTime <= filterForm.endTime/1000){
                    element.style.stroke = "#409eff"
                }
            }

        }

    });

    return {
        nodes,
        edges
    }
}
/**
 * 过滤，只显示交易所
 * 
 * @params {relationData } 图的原数据
 */
export const filterLabelNode = function (relationData){
    let nodes = relationData.nodes;
    let edges = relationData.edges;
    
    nodes = nodes.filter(ele =>{
        if(ele.extraData && ele.extraData.isLabel) return ele
    })

    if(nodes.length<=0) return 

    edges = edges.filter(ele=>{
        let node = nodes.filter(element=>element.id==ele.source||element.id==ele.target)
        if(node.length>0){
            return ele
        }
    })

    return {
        nodes,
        edges
    }
}