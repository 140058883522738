/**
 * 防抖
 */
let time = null;//time用来控制事件的触发
 export const debounce = (fn, delay) => {
    if (time !== null) {
        clearTimeout(time);
    }
    time = setTimeout(() => {
        fn.call(this);
        //利用call(),让this的指针从指向window 转成指向input
    }, delay)
}
