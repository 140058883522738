<template>
  <div class="proj-list">
    <!-- 标题 -->
    <div class="title">
      <img class="title-img" src="@/assets/chart/icon-chart-proj-list.png" />
      <span>项目列表</span>
    </div>

    <div class="proj-add" @click="projAddHandler">+ 添加项目</div>
    <!-- 列表内容 -->
    <div class="list">
      <!-- 项目列 -->
      <div class="list-cell" v-for="(proj, projIndex) in projList" :key="projIndex">
        <div
          class="proj-item"
          @click="showProjRel(proj)"
          @mouseenter="mouseenterProj(projIndex)"
          @mouseleave="mouseleaveProj(projIndex)"
        >
          <img class="arrow" v-if="proj.showRelList" src="@/assets/chart/icon-arrow-down.png" />
          <img class="arrow" v-else src="@/assets/chart/icon-arrow-up.png" />
          <el-tooltip
            class="item"
            effect="dark"
            :content="proj.name"
            placement="top-start"
            :open-delay="1000"
          >
            <div class="name">{{ proj.name }}</div>
          </el-tooltip>

          <el-popover
            placement="bottom"
            trigger="hover"
            width="150"
            popper-class="proj-list-popover"
          >
            <div @click="relAddHandler(proj)" class="popover-item">
              <img src="@/assets/images/proj/icon-proj-add.png" />添加图谱
            </div>
            <div @click="projUpdateHandler(proj)" class="popover-item" style="margin: 16px 0">
              <img src="@/assets/images/proj/icon-proj-rename.png" />重命名
            </div>
            <div @click="projDelHandler(proj)" class="popover-item">
              <img src="@/assets/images/proj/icon-proj-delete.png" />删除项目
            </div>

            <div class="option" slot="reference" ref="projOption">
              <img class="option-img" src="@/assets/chart/icon-control.png" />
            </div>
          </el-popover>
        </div>
        <!-- 图谱列 -->
        <div class="rel-list" v-if="proj.showRelList">
          <div
            class="rel-item"
            v-for="(rel, relIndex) in proj.relList"
            :key="relIndex"
            @click="relSelectd(rel)"
            :class="{ 'rel-item-act': rel.isSelected }"
            @mouseenter="mouseenterRel(relIndex)"
            @mouseleave="mouseleaveRel(relIndex)"
          >
            <el-tooltip
              class="item"
              effect="dark"
              :content="rel.name"
              placement="top-start"
              :open-delay="1000"
            >
              <div class="name">{{ rel.name }}</div>
            </el-tooltip>
            <el-popover
              placement="bottom"
              trigger="hover"
              width="150"
              popper-class="proj-list-popover"
            >
              <div @click="relUpdateHandler(rel, proj)" class="popover-item">
                <img src="@/assets/images/proj/icon-proj-rename.png" />重命名
              </div>
              <div @click="relDelHandler(rel, proj)" class="popover-item" style="margin-top: 16px">
                <img src="@/assets/images/proj/icon-proj-delete.png" />删除图谱
              </div>

              <div class="option" slot="reference" ref="relOption">
                <img class="option-img" src="@/assets/chart/icon-control.png" />
              </div>
            </el-popover>
          </div>
        </div>
      </div>
    </div>

    <div class="pagination">
      <el-pagination
        class="proj_table_pagination"
        :background="true"
        layout="prev,pager,next"
        :total="total"
        :page-size="pageSize"
        :current-page="pageNo"
        @current-change="currentChange"
        :pager-count="pagerCount"
        :hide-on-single-page="true"
        small
      >
      </el-pagination>
    </div>

    <!-- 弹窗部分 -->
    <el-dialog
      :title="isAddProj ? '添加项目' : '重命名'"
      :visible.sync="showProjDia"
      :center="true"
      :destroy-on-close="true"
      width="480px"
      custom-class="proj_dialog"
      :close-on-click-modal="false"
    >
      <div>
        <el-form
          label-width="0"
          label-position="left"
          :model="projForm"
          :rules="rules"
          ref="projForm"
        >
          <el-form-item prop="name">
            <el-input
              v-model.trim="projForm.name"
              placeholder="请输入项目名称"
              :maxlength="50"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button plain @click="showProjDia = false">取 消</el-button>
        <el-button type="primary" @click="saveProj()">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
      :title="isAddRel ? '添加图谱' : '重命名'"
      :visible.sync="showRelDia"
      :center="true"
      :destroy-on-close="true"
      width="480px"
      custom-class="proj_dialog"
      :close-on-click-modal="false"
    >
      <div>
        <el-form label-width="0" label-position="left" :model="relForm">
          <el-form-item>
            <el-input
              v-model="relForm.name"
              placeholder="请输入图谱名称"
              :maxlength="50"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showRelDia = false">取 消</el-button>
        <el-button type="primary" @click="saveRel()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  getProjectList,
  addProject,
  updetaProject,
  delPrj,
  getProjectDetail,
  addRel,
  updetaRel,
  delRel,
} from "@/api/relation";
import ProjEdit from "./ProjEdit";
import { debounce } from "@/utils/util.js";

let _curProj = null;

export default {
  name: "proj-list",
  components: {
    ProjEdit,
  },
  data() {
    return {
      projList: [],
      pageNo: 1,
      pageSize: 15,
      total: 0,
      pagerCount: 5,

      isAddProj: true,
      showProjDia: false,
      projForm: {
        code: null,
        name: null,
      },

      isAddRel: true,
      showRelDia: false,
      relForm: {
        name: null,
      },

      curRel: {
        isSelected: false,
      },
      rules: {
        name: [
          { required: true, message: "请输入项目名称", trigger: "blur" },
          { min: 1, message: "项目名称不能为空", trigger: "blur" },
        ],
      },
    };
  },
  mounted() {
    this.getProjectList();
  },
  methods: {
    getProjectList() {
      getProjectList(this.pageNo, this.pageSize).then((res) => {
        this.projList = res.data.data.map((ele) => {
          return {
            ...ele,
            showRelList: false,
            relList: [],
          };
        });
        this.total = res.data.total;
      });
    },
    currentChange(val) {
      this.pageNo = val;
      this.getProjectList();
    },
    // 添加项目按钮回调
    projAddHandler() {
      this.projForm.name = null;
      this.isAddProj = true;
      this.showProjDia = true;
    },
    // 重命名项目按钮回调
    projUpdateHandler(proj) {
      this.projForm.id = proj.id;
      this.projForm.name = proj.name;
      this.isAddProj = false;
      this.showProjDia = true;
    },
    // 删除项目按钮回调
    projDelHandler(proj) {
      this.$confirm("确定删除项目?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        delPrj(proj.id).then((res) => {
          if (res.data.code == "0") {
            this.$message.success("删除成功");
            this.getProjectList();
          }
        });
      });
    },
    saveProj() {
      debounce(() => {
        this.saveProjHandle();
      }, 1000);
    },
    saveProjHandle() {
      this.$refs.projForm.validate((valid) => {
        if (valid) {
          if (this.isAddProj) {
            addProject(this.projForm).then((res) => {
              if (res.data.code == "0") {
                this.$message.success("项目新增成功");
              }
              this.pageNo = 1;
              this.getProjectList();
              this.showProjDia = false;
            });
          } else {
            updetaProject(this.projForm).then((res) => {
              if (res.data.code == "0") {
                this.$message.success("项目重命名成功");
              }
              this.pageNo = 1;
              this.getProjectList();
              this.showProjDia = false;
            });
          }
        }
      });
    },
    // 添加图谱按钮回调
    relAddHandler(proj) {
      //记录操作的项目
      _curProj = proj;

      this.relForm.prjId = proj.id;
      this.relForm.name = null;
      this.relForm.code = null;
      this.isAddRel = true;
      this.showRelDia = true;
    },
    // 重命名图谱按钮回调
    relUpdateHandler(rel, proj) {
      //记录操作的项目
      _curProj = proj;

      this.relForm.id = rel.id;
      this.relForm.name = rel.name;
      this.isAddRel = false;
      this.showRelDia = true;
    },
    // 删除图谱按钮回调
    relDelHandler(rel, proj) {
      this.$confirm("确定删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        delRel(rel.id).then((res) => {
          if (res.data.code == "0") {
            this.$message.success("删除成功");
            this.getProjectDetail(proj);
          }
        });
      });
    },
    saveRel() {
      debounce(() => {
        this.saveRelHander();
      }, 1000);
    },
    saveRelHander(){
      if (this.isAddRel) {
        addRel(this.relForm).then((res) => {
          if (res.data.code == "0") {
            this.$message.success("图谱新增成功");
          }
          this.getProjectDetail(_curProj);
          this.showRelDia = false;
        });
      } else {
        updetaRel(this.relForm).then((res) => {
          if (res.data.code == "0") {
            this.$message.success("图谱修改成功");
          }
          this.getProjectDetail(_curProj);
          this.showRelDia = false;
        });
      }
    },
    // 懒加载项目图谱列表
    showProjRel(proj) {
      this.$emit("projSelectd", proj);

      if (proj.showRelList == true) {
        proj.showRelList = false;
        return;
      }

      getProjectDetail(proj.id).then((res) => {
        proj.relList = res.data.data.relationProjectVos.map((ele) => {
          return {
            ...ele,
            isSelected: false,
          };
        });
        proj.showRelList = true;
      });
    },
    // 接口：查询项目下面图谱列表
    getProjectDetail(proj) {
      getProjectDetail(proj.id).then((res) => {
        proj.relList = res.data.data.relationProjectVos.map((ele) => {
          return {
            ...ele,
            isSelected: false,
          };
        });
      });
    },
    relSelectd(rel) {
      this.curRel.isSelected = false;
      this.curRel = rel;
      this.curRel.isSelected = true;
      this.$emit("relSelectd", rel);
    },
    mouseenterProj(index) {
      this.$refs.projOption[index].style.opacity = 1;
    },
    mouseleaveProj(index) {
      this.$refs.projOption[index].style.opacity = 0;
    },
    mouseenterRel(index) {
      this.$refs.relOption[index].style.opacity = 1;
    },
    mouseleaveRel(index) {
      this.$refs.relOption[index].style.opacity = 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.proj-list {
  width: 260px;
  .title {
    margin-top: 28px;
    font-size: 18px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ececec;
    padding-bottom: 9px;

    .title-img {
      height: 24px;
      width: 24px;
      margin: 0 20px;
    }
  }

  .list {
    .list-cell {
      border-bottom: 1px solid #ececec;

      .proj-item {
        height: 48px;
        display: flex;
        align-items: center;
        padding: 0 14px;
        font-size: 16px;
        color: #333333;

        .arrow {
          height: 14px;
          width: 14px;
        }

        .code {
          margin-left: 10px;
        }

        .name {
          margin: 0 10px;
          flex: 1;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          cursor: pointer;
        }

        .option {
          height: 15px;
          display: flex;
          align-items: center;
          opacity: 0;

          .option-img {
            height: 3px;
            width: 15px;
            cursor: pointer;
            z-index: 99;
          }
        }
        .option:hover {
          opacity: 1;
        }
      }

      .rel-item {
        height: 50px;
        display: flex;
        align-items: center;
        font-size: 14px;
        padding: 0 14px;

        .name {
          margin-left: 16px;
          flex: 1;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          cursor: pointer;
        }

        .option {
          height: 15px;
          display: flex;
          align-items: center;
          opacity: 0;

          .option-img {
            height: 3px;
            width: 15px;
            cursor: pointer;
          }
        }
        .option:hover {
          opacity: 1;
        }
      }
      .rel-item-act {
        background-color: #f2f3f6;
        color: #2e43eb;
      }
    }
  }
  .pagination {
    margin-top: 30px;
  }

  .proj-add {
    height: 34px;
    line-height: 34px;
    border-radius: 4px;
    border: 1px solid #2a66ed;
    color: #2a66ed;
    font-size: 14px;
    text-align: center;
    margin: 20px 23px;
    cursor: pointer;
  }
  .proj-add:hover {
    background: #dcdff4;
  }
  /deep/.el-dialog {
    .dialog-footer {
      display: flex;
      justify-content: space-between;
    }
  }
}
</style>

<style lang="scss">
.proj-list-popover {
  width: 130px !important;
  min-width: 130px !important;
  .popover-item {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
}
</style>
