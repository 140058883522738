<template>
  <div class="chart-edit">
    <div class="chart-edit-name">
      {{ type == 1 ? "资产信息" : "交易记录" }}
      <img src="@/assets/chart/icon-info-hide.png" @click="closeEditBtn" />
    </div>
    <!-- 地址信息 左侧弹窗 -->
    <div class="chart-edit-info" v-if="type == 1 && addrInfo.address">
      <div class="chart-edit-item">
        <div :span="11" class="chart-edit-title">地址</div>
        <div class="chart-edit-content" style="font-size: 13px">
          {{ addrInfo.address.substring(0, 7) }}...{{
            addrInfo.address.substring(addrInfo.address.length - 7, addrInfo.address.length)
          }}
          <el-button
            v-clipboard="addrInfo.address"
            type="text"
            icon="el-icon-document-copy"
            style="height: 16px; padding: 0"
            @success="clipboardSuccess"
          ></el-button>
        </div>
      </div>
      <div class="chart-edit-item">
        <div :span="11" class="chart-edit-title" style="line-height: 25px">标签</div>
        <div class="chart-edit-content">
          {{ curNode.addr }}
          <div class="label-container">
            <div class="label-container-item" v-for="item in addrInfo.label" :key="item">
              {{ item }}
            </div>
          </div>
        </div>
      </div>
      <div class="chart-edit-item" v-for="(item, index) in nodeInfoList" :key="index">
        <div :span="11" class="chart-edit-title">{{ item.name }}</div>
        <div class="chart-edit-content" style="font-size: 13px">
          {{ addrInfo[item.key] }}{{ item.isMoney && addrInfo[item.key] ? tokenType : item.unit }}
        </div>
      </div>
    </div>

    <!-- 交易信息 左侧弹窗 -->
    <div class="chart-trade-info" v-if="type == 2">
      <div class="trade-info">
        <div class="trade-info-addr">
          {{ curNode.source.substring(0, 3) }}...{{
            curNode.source.substring(curNode.source.length - 3, curNode.source.length)
          }}
          <el-button
            v-clipboard="curNode.source"
            type="text"
            icon="el-icon-document-copy"
            style="height: 16px; padding: 0"
            @success="clipboardSuccess"
          ></el-button>
        </div>
        <div class="trade-info-amount">-({{ curNode.label }}ETH)-</div>
        <div class="trade-info-addr">
          {{ curNode.target.substring(0, 3) }}...{{
            curNode.target.substring(curNode.target.length - 3, curNode.target.length)
          }}
          <el-button
            v-clipboard="curNode.target"
            type="text"
            icon="el-icon-document-copy"
            style="height: 16px; padding: 0"
            @success="clipboardSuccess"
          ></el-button>
        </div>
      </div>
      <div class="trade-table">
        <el-table
          :data="tradeList"
          style="margin: 0 20px; width: 280px;"
          :border="true"
          :header-cell-style="headerRowStyle"
          size="mini"
          height="100%"
        >
          <el-table-column prop="date" label="时间" :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column prop="symbol" label="币种" :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column prop="method" label="方法" :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column prop="value" label="数量" :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column label="金额$" :show-overflow-tooltip="true">
            <template slot-scope="scope">${{ scope.row.amount }}</template>
          </el-table-column>
          <el-table-column prop="hash" label="交易哈希" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              <el-button
                v-clipboard="scope.row.hash"
                type="text"
                icon="el-icon-document-copy"
                style="height: 16px; padding: 0"
                @success="clipboardSuccess"
              ></el-button>
             <span class="trade-table-hash" @click="gotoDetail(scope.row.hash)">{{ scope.row.hash }}</span>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          layout="prev, pager, next"
          :total="tradeTotal"
          :page-size="tradePageSize"
          :current-page="tradePageNo"
          @current-change="tradeChange"
          :pager-count="tradePageCount"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { getMultiTxnDetail } from "@/api/relation";
import { dateFormat } from "@/utils/date.js";
export default {
  name: "relation-title",
  props: {
    type: {
      default: 1,
      type: Number,
    },
    tokenType: {
      default: "ETH",
      type: String,
    },
    addrInfo: Object,
    curNode: Object,
  }, 
  data() {
    return {
      tradeTotal: 0,
      tradePageNo: 1,
      tradePageSize: 10,
      tradePageCount: 5,
      headerRowStyle: {
        backgroundColor: "rgb(244, 247, 253)",
        color: "333333",
      },
      tradeList: [],
      nodeInfoList: [
        {
          name: "余额",
          key: "balance",
          unit:"$"
        },
        {
          name: "首次交易时间",
          key: "firstTransactionTime",
        },
        {
          name: "最近交易时间",
          key: "lastTransactionTime",
        },
        {
          name: "交易次数",
          key: "transactionCount",
        },
        // {
        //   name: "最大一笔交易额",
        //   key: "maxTxnAmount",
        //   isMoney: true,
        // },
        {
          name: "累计接收金额",
          key: "receiveAmount",
          isMoney: true,
        },
        {
          name: "累计发送金额",
          key: "sendAmount",
          isMoney: true,
        },
      ],
      curEdge: null,
    };
  },
  mounted() {},
  methods: {
    closeEditBtn() {
      this.$emit("closeEditBtn");
    },
    clearInfo(){
      this.type = 0
    },
    tradeChange(val) {
      this.tradePageNo = val;
      this.getMultiTxnDetail();
    },
    update(curEdge) {
        this.tradePageNo = 1;
        this.curEdge = curEdge;
        this.getMultiTxnDetail();
    },
    clipboardSuccess(){
      this.$message.success("复制成功");
    },
    gotoDetail(hash){
      window.open("https://etherscan.io/tx/"+hash)
    },  
    // 查询交易详情
    getMultiTxnDetail() {
      getMultiTxnDetail(
        this.curEdge.source,
        this.curEdge.target,
        this.tokenType,
        this.tradePageNo,
        this.tradePageSize,
        this.curEdge.extraData.minTime,
        this.curEdge.extraData.maxTime,
        this.curEdge.extraData.hash
      )
        .then((res) => {
          console.log(res.data.data);
          if(res.data.data){
            this.tradeList = res.data.data.data.map((item) => {
              return {
                ...item,
                date: dateFormat(new Date(item.blocktime), "yyyy-MM-dd"),
              };
            });
            this.tradeTotal = res.data.data.total;
          }
        })
        .catch((err) => {
          this.$message.error("网络错误，请重试");
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.chart-edit {
  height: 100%;
  width: 100%;
  background-color: rgba(23, 128, 224, 0);
  display: flex;
  flex-direction: column;
  .chart-edit-name {
    height: 39px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 26px;
    color: #202038;
    margin-top: 27px;
    margin-left: 20px;
    img{
      height: 39px;
      width: 19px;
    }

  }

  .chart-edit-info {
    margin: 0 20px;
    margin-top: 32px;
    flex: 1;
    .chart-edit-item {
      font-size: 12px;
      display: flex;
      justify-content: space-between;
      margin-bottom: 12px;
    }
    .chart-edit-content{
      flex: 1;
      text-align: right;
    }
  }

  .chart-edit-title {
    text-align: left;
    color: #707a83;
    font-size: 12px;
  }

  .label-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: right;
    &-item {
      height: 18px;
      line-height: 18px;
      background: #fff;
      border-radius: 13px;
      color: #3f3f54;
      text-align: center;
      font-size: 11px;
      margin-right: 8px;
      border: 1px solid #b8c8f2;
      padding: 0 6px;
    }
  }
  .chart-trade-info{
    flex: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }
  .trade-info {
    height: 40px;
    background-color: rgb(244, 247, 253);
    display: flex;
    align-items: center;
    margin: 20px;

    .trade-info-addr {
      font-size: 13px;
    }

    .trade-info-amount {
      font-size: 13px;
      flex: 1;
      text-align: center;
    }
  }

  .trade-table {
    font-size: 13px;
    margin: 20px 0;
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-y: scroll;
    .trade-table-hash{
      cursor: pointer;
    }
  }
}
</style>
