<template>
  <div class="searchInfo" ref="searchInfo">
    <div class="address">
      <div class="address-input">
        <!-- 主链下拉 -->
        <el-select v-model="filters.tokenCode" placeholder="请选择" @change="tokenSelected">
          <div slot="prefix">
            <img width="20" height="20" style="margin-top: 10px; margin-left: 10px" :src="curImg" />
          </div>
          <el-option v-for="item in keyList" :key="item.code" :label="item.code" :value="item.code">
            <div class="bit-select-item">
              <img width="20" height="20" :src="item.img" />
              <div class="bit-select-item-name">{{ item.code }}</div>
            </div>
          </el-option>
        </el-select>
        <!-- 分割线 -->
        <div class="divide"></div>
        <!-- 地址输入框 -->
        <div class="input">
          <input
            class="input-item"
            v-model="filters.address"
            placeholder="请输入地址，按“回车键”结束"
            @focus="addressFocus"
          />
        </div>
        <div class="search-btn" @click="submitClick">
          <img class="search-btn-img" src="@/assets/chart/btn-search.png" /> 关系分析
        </div>
      </div>
    </div>
    <div class="address-other">
      <el-form ref="form" :model="filters" label-width="80px" label-position="right">
        <div class="form-row">
          <el-form-item label-width="0">
            <el-date-picker
              v-model="daterange"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="timestamp"
              :unlink-panels="true"
              :picker-options="pickerOptions"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="节点拓展">
            <el-input v-model="filters.maxNextTxs" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="挖掘层数">
            <el-select v-model="filters.layer" placeholder="请选择">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label-width="0">
            <div class="io">
              <div class="io-item" :class="{ 'io-item-act': curIO == 0 }" @click="curIO = 0">
                流入方向
              </div>
              <div class="io-item" :class="{ 'io-item-act': curIO == 1 }" @click="curIO = 1">
                流出方向
              </div>
            </div>
          </el-form-item>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import { getKeyList } from "@/api/relation";
export default {
  name: "relation-search",
  props: {
    addrInfo: Object,
  },
  watch: {
    addrInfo: function () {
      this.filters.address = this.addrInfo.addrs;
      this.filters.id = this.addrInfo.id;
    }
  },
  data() {
    return {
      addrInput: null,
      curImg: null,
      relId: null,
      graph: null,
      filters: {
        address:"",
        tokenCode: null,
        layer: 3,
        to: true,
        from: false,
        exchange: false,
        startDate: 0,
        endDate: 0,
        maxNextTxs: 5,
      },
      daterange: [new Date().getTime()-180*24*60*60*1000,new Date().getTime()],
      keyList: [],
      checkList: [],
      curIO: 1,
      options: [
        {
          value: "1",
          label: 1,
        },
        {
          value: "2",
          label: 2,
        },
        {
          value: "3",
          label: 3,
        },
        {
          value: "4",
          label: 4,
        },
        {
          value: "5",
          label: 5,
        },
      ],
      pickerOptions:{
        
        shortcuts: [{
            text: '过去3天',
            onClick(picker) {
              picker.$emit('pick', [new Date()-3*24*60*60*1000, new Date()]);
            }
          }, {
            text: '过去7天',
            onClick(picker) {
              picker.$emit('pick', [new Date()-7*24*60*60*1000, new Date()]);
            }
          }, {
            text: '过去30天',
            onClick(picker) {
              picker.$emit('pick', [new Date()-30*24*60*60*1000, new Date()]);
            }
          }, {
            text: '过去60天',
            onClick(picker) {
              picker.$emit('pick', [new Date()-60*24*60*60*1000, new Date()]);
            }
          }]
      }
    };
  },
  created() {
    this.getKeyList();
  },
  methods: {
    // 获取币种列表
    getKeyList(tokenCode) {
      getKeyList().then((res) => {
        this.keyList = res.data.data;
        this.curImg = this.keyList[0].img;
        this.filters.tokenCode = this.keyList[0].token;
      });
    },
    setTokenCode(tokenCode) {
      this.filters.tokenCode = tokenCode;

      let item = this.keyList.find((item) => {
        return item.code == tokenCode;
      });
      
      if(item)
        this.curImg = item.img;
    },
    submitClick() {

      if(this.filters.address == null){
        this.$message.error("请输入地址");
        return
      }

      if(!this.filters.id){
        this.$message.error("请选择图谱");
        return
      }

      if (this.curIO == 0) {
        this.filters.from = true;
        this.filters.to = false;
      } else {
        this.filters.from = false;
        this.filters.to = true;
      }
      
      if (this.daterange && this.daterange.length > 0) {
        this.filters.startDate = this.daterange[0] / 1000;
        this.filters.endDate = this.daterange[1] / 1000;
      }else{
        this.filters.startDate = null
        this.filters.endDate = null
      }
      this.$emit("submitClick", this.filters);
    },
    tokenSelected(e) {
      let item = this.keyList.find((item) => {
        return item.code == e;
      });
      console.log(item);
      this.curImg = item.img;
    },
    addressFocus(){
      if(!this.filters.id){
        this.$message.error("请选择图谱");
      }
    },
    resetSearch(){

      this.filters.maxNextTxs = 5;
      this.filters.layer = 3;
      this.filters.to = 5;
      this.filters.from = false;
      this.daterange = [new Date().getTime()-180*24*60*60*1000,new Date().getTime()];
    }
  },
};
</script>
<style lang="scss" scoped>
.searchInfo {
  display: flex;
  flex-direction: column;
  // background-color: #f8fafd;

  .address {
    width: 1200px;
    height: 48px;
    background-color: #f2f3fa;
    border-radius: 4px;

    .address-input {
      display: flex;
      height: 100%;
      align-items: center;

      /deep/.el-select {
        margin-left: 20px;
        border: none;

        .el-input__inner {
          border: none;
          width: 140px;
          text-align: center;
          color: #202038;
          background-color: transparent;
        }
      }

      .divide {
        width: 1px;
        height: 32px;
        background-color: #979797;
        margin-right: 10px;
      }

      .lab {
        display: flex;
        align-items: center;

        .lab-item {
          height: 36px;
          line-height: 36px;
          padding: 0 15px;
          border-radius: 21px;
          border: 1px solid #c2c2c2;
          color: #c2c2c2;
          font-size: 11px;
          text-align: center;
          margin-right: 5px;
          text-overflow: ellipsis;
          /* ellipsis:显示省略符号来代表被修剪的文本  string:使用给定的字符串来代表被修剪的文本*/
          white-space: nowrap;
          /* nowrap:规定段落中的文本不进行换行   */
          overflow: hidden;

          .lab-item-close {
            margin-left: 10px;
            cursor: pointer;
          }
        }
      }

      .input {
        flex: 1;
        display: flex;
        align-items: center;

        .input-item {
          flex: 1;
          height: 36px;
          line-height: 36px;
          border: none;
          font-size: 14px;
          background-color: transparent;
        }

        .input-item:focus {
          border: none;
          outline: 0px;
        }

        .input-item::-webkit-input-placeholder {
          /* WebKit browsers*/
          color: #c2c2c2;
        }
      }

      .search-btn {
        height: 48px;
        line-height: 48px;
        width: 180px;
        background: #2e43eb;
        color: #fff;
        cursor: pointer;
        font-size: 18px;
        justify-content: center;
        align-items: center;
        display: flex;
        img {
          margin-right: 8px;
        }
      }
    }
  }

  /deep/.address-other {
    flex: 1;
    margin-top: 20px;

    .form-row {
      display: flex;
      .el-date-editor.el-input__inner {
        width: 330px;
      }
      .el-input {
        width: 100px;
      }
    }
    .el-form-item {
      margin-bottom: 0;
    }
    .el-form-item__label {
      font-family: PingFangSC-Regular;
    }
  }

  .io {
    width: 240px;
    height: 40px;
    background: #ffffff;
    border-radius: 4px;
    border: 1px solid #eaeaea;
    display: flex;
    align-items: center;
    margin-left: 66px;
    color: #202038;
    padding: 6px;
    box-sizing: border-box;
    justify-content: space-evenly;
    .io-item {
      width: 113px;
      height: 34px;
      line-height: 34px;
      border-radius: 4px;
      text-align: center;
      cursor: pointer;
    }
    .io-item-act {
      background: #5c6eff;
      color: #fff;
    }
  }
}

.rel-search {
  display: flex;
}

.del {
  height: 20px;
  line-height: 20px;
  width: 20px;
  color: white;
  background-color: red;
  font-size: 10px;
  position: absolute;
  right: -10px;
  top: -10px;
  text-align: center;
  border-radius: 10px;
  cursor: pointer;
}

.bitToken {
  /deep/.el-select {
    .el-input__inner {
      padding-left: 40px;
    }
  }
}
</style>
<style lang="scss">
.bit-select-item {
  height: 34px;
  line-height: 34px;
  display: flex;
  align-items: center;

  &-name {
    height: 34px;
    line-height: 34px;
    margin-left: 8px;
  }
}
</style>
