<template>
  <div class="proj-edit">
    <el-form label-position="top" label-width="40px" :model="projForm">
      <el-form-item label="项目名称">
        <el-input v-model="projForm.projName" placeholder="请输入项目名称" :maxlength="50"></el-input>
      </el-form-item>
      <el-form-item label="图谱名称">
        <div class="rel-list">
        <div class="rel-item" v-for="(item,index) in projForm.relList" :key="index">
          <el-input v-model="item.name" placeholder="请输入图谱名称" :maxlength="50"></el-input>
          <img class="rel-item-img" src="@/assets/chart/icon-rel-del.png" />
        </div>

        </div>
      </el-form-item>
    </el-form>
    <div class="rel-add" @click="addHandler">
      添加新图谱<i class="el-icon-circle-plus-outline" style="font-size:24px;margin-left:14px;"></i>
    </div>
  </div>
</template>

<script>
import { getProjectDetail } from "@/api/relation";
export default {
  name: "proj-edit",
  props:{
    proj:Object
  },
  data() {
    return {
      projForm: {
        projName: null,
        relList: [],
      },
    };
  },
  mounted() {
    getProjectDetail(this.proj.id).then((res) => {
      
      this.projForm.projName =  res.data.data.name
      this.projForm.relList = res.data.data.relationProjectVos;
    });
  },
  methods: {
    addHandler(){
      this.projForm.relList.push({
        name:null
      })
    }
  },
};
</script>

<style lang="scss" scoped>
.proj-edit {
  .rel-list{
    max-height: 200px;
    overflow-y: scroll;
  }
  .rel-item {
    display: flex;
    align-items: center;
    .rel-item-img{
      height: 24px;
      width: 24px;
      margin-left: 20px;
      cursor: pointer;
    }
    margin-bottom: 16px;
  }
  .rel-add{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    line-height: 50px;
    width: 300px;
    text-align: center;
    border: 1px dashed #EAEAEA;
    font-size: 16px;
    margin: 0 auto;
    cursor: pointer;
    color: #333333;
  }
}
</style>
